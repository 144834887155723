import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/home",
        name: "首页",
        component: () => import("../views/home/index"),
        meta: {
            title: "首页",
        },
    },
    {
        path: "/intro",
        name: "走进我们",
        component: () => import("../views/intro/index"),
        meta: {
            title: "走进我们",
        },
        children: [
            {
                path: "/intro",
                redirect: "/intro/profile",
            },
            {
                path: "profile",
                component: () => import("../views/intro/profile"),
                meta: {
                    title: "公司简介",
                },
            },

            {
                path: "certificat",
                component: () => import("../views/intro/certificat"),
                meta: {
                    title: "资质证书",
                },
            },
            {
                path: "equipment",
                component: () => import("../views/intro/equipment"),
                meta: {
                    title: "厂房设备",
                },
            },
        ],
    },

    {
        path: "/productShow",
        name: "产品展示",
        component: () => import("../views/productShow/index"),
        meta: {
            title: "产品展示",
        },
    },
    {
        path: "/productDetails",
        name: "产品详情",
        component: () => import("../views/productShow/details"),
        meta: {
            title: "产品详情",
        },
    },
    {
        path: "/companyNews",
        name: "公司新闻",
        component: () => import("../views/news/companyNews"),
        meta: {
            title: "公司新闻",
        },
    },
    {
        path: "/IndustryTrends",
        name: "行业动态",
        component: () => import("../views/news/IndustryTrends"),
        meta: {
            title: "行业动态",
        },
    },
    {
        path: "/newsDetails",
        name: "资讯详情",
        component: () => import("../views/news/details"),
        meta: {
            title: "资讯详情",
        },
    },
    {
        path: "/contact",
        name: "联系我们",
        component: () => import("../views/contact/index"),
        meta: {
            title: "联系我们",
        },
    },
]

const scrollBehavior = function (to, from, savedPosition) {
    // savedPosition 会在你使用浏览器前进或后退按钮时候生效
    // 这个跟你使用 router.go() 或 router.back() 效果一致
    // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
    if (savedPosition) {
        return savedPosition
    } else {
        // 如果不是通过上述行为切换组件，就会让页面回到顶部
        return { x: 0, y: 0 }
    }
}

const router = new VueRouter({
    mode: "history",
    scrollBehavior,
    base: process.env.BASE_URL,
    routes,
})

export default router
